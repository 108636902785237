.section {
    &.newsletter-section {
        .newsletter-title {
            color: color('primary');
        }

        .newsletter-text {
            color: color('text', 'light');
            margin-bottom: 0;

            p {
                font-size: 14px;
            }
        }

        label {
            color: color('white');
            font-family: font(sub);
            font-size: 14px;
            text-align: center;
            display: none;
        }

        input {
            background: transparent;
            width: 500px;
            border: 1px solid #b2b2b2;
            color: color('primary');
            text-align: left;
            margin: 0 20px;
            @include placeholder(13px, 300, normal, #999999);
            @include border-radius(0);

            @media (max-width: $media_md) {
                width: 350px;
            }

            @media (max-width: 415px) {
                width: 250px;
            }

            &:focus {
                box-shadow: unset;
                border: 1px solid #000;
            }
        }

        .cb {
            i {
                &.fas {
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    display: none;
                }
            }
        }

        .newsletter-btn {
            background: color('primary');
            color: color('white');
            border: 1px solid color('primary');
            @include border-radius(0);
            font-size: 13px;
            line-height: 31px;
            position: relative;
            top: -1px;

            &:after {
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                content: "\f105";
                position: relative;
                top: 1px;
                margin-left: 50px;
            }

            &:hover {
                color: color('primary');
                background: transparent;
            }
        }
    }
}

.newsletter-form-wrapper {
    .newsletter-text {
        display: inline-block;
    }

    form {
        display: inline-block;

        .form-group {
            display: inline-block;
        }
    }
}

// popup
.newsletter-confirm0 {
    padding: 40px 20px !important;

    .btn-wrapper {
        margin-top: 10px;
    }

    p {
        font-family: font(sub);
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        padding-bottom: 1rem;
        display: inline-block;
        margin-left: 10px;
    }
}