/* THEME COLORS */
$colors: (
    'primary': #000,
    'secondary': #adc400,
    'terniary': #1870b4,
    'white': #ffffff,
    'black': #000000,
    'error': #c90000,
    'background': (
        'main': #ffffff,
        'alt': #f6f6f6,
        'dark': #36434d,
    ),
    'text': (
        'dark': #000,
        'darker': #333333,
        'light': #666,
        'placeholder': #b5d1ee,
        'subtitle': #daaf5c,
    ),
    'border': (
        'dark': #ccc,
        'light': #e6e6e6,
    ),
    'nav': (
        'text':(
            'main': #000,
            'accent': #777777,
        ),
        'background': (
            'main': #fff,
            'accent': #f2f2f2,
        ),
    ),
    'footer':(
        'text': (
            'main': #000,
            'accent': #6e7882,
            'social_icons': #2f363d,
        ),
        'background': (
            'main': #FFF,
            'accent': #1b1e22,
        ),
    ),
    'calendar': (
        'header': (
            'background': 'primary',
            'text': 'white'
        ),
        'default': (
            'background': #eee5e0,
            'text': #8a7768,
        ),
        'weekend': (
            'background': #cccccc,
        ),
        'unavailable': (
            'background': primary,
            'text': #621b00,
        ),
    ),
    dark: #475966,
);

@function color($name, $subname: '', $deepname: '', $map: $colors) {
    @if map-has-key($map, $name) {
        @if type-of(map-get($map, $name))=="map" {
            @if type-of(map-deep-get($colors, $name, $subname))=="map" {
                $name: map-deep-get($colors, $name, $subname, $deepname);

                @if $name==null {
                    @warn "Unknown color name entered for 3rd arg of color function: `#{$deepname}`, in map: $colors.";
                }
            } @else {
                $name: map-deep-get($colors, $name, $subname);

                @if $name==null {
                    @warn "Unknown color name entered for 2nd arg of color function: `#{$subname}`, in map: $colors.";
                }
            }
        } @else {
            $name: map-get($colors, $name) or $name;
        }

        @if map-has-key($colors, $name) {
            $name: color($name);
        }

        @if type-of($name)=="list" {
            @if length($name)==2 {
                $cname1: nth($name, 1);
                $cname2: nth($name, 2);
                $name: color($cname1, $cname2);
            }

            @if length($name)==3 {
                $cname1: nth($name, 1);
                $cname2: nth($name, 2);
                $cname3: nth($name, 3);
                $name: color($cname1, $cname2, $cname3);
            }
        }

        @return $name;

    }

    @warn "Unknown color name entered for 1st arg of color function: `#{$name}`, in map: $colors.";
    @return null;
}

:root {
    /* BOOTSTRAP ROOT VARS
  // Uncomment to change vars
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #ffffff;
    --gray: #868e96;
    --gray-dark: #343a40;
  */
    --primary: #1870cb;
    --secondary: #adc400;
    --terniary: #1870b4;
    /*
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
  */
    --dark: #475966;
    /*
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
  */
}