.comments_slider_init {
    .slider_item {
        .comment-quote-start {
            background: url('../../../images/icons/quote-start.png')
        }

        .comment-quote-end {
            background: url('../../../images/icons/quote-end.png')
        }
    }
}

.header-slider-wrapper {
    .navi-img {
        &.navi-prev {
            background: url('../../../images/icons/slider-prev.png') no-repeat;
        }

        &.navi-next {
            background: url('../../../images/icons/slider-next.png') no-repeat;
        }
    }
}

.section {
    // &.newsletter-section {
    //     background-image: url('../../../images/background/section-bg.jpg');
    //     background-position: center;
    //     background-attachment: fixed;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    // }
}