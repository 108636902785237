.blog-section {
    .blog-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .pagi-container {
        margin-top: 5rem;
        margin-bottom: 1rem;

        .pagination {
            font-size: 1.143rem;
            font-family: font(sub);
            font-weight: 300;

            .page-item {
                color: color('text', 'darker');

                &.active {
                    color: color('white') !important;
                    background: color('primary');
                }

                &.prev {
                    &.disabled {
                        .page-link {
                            color: color('text', 'dark') !important;
                            opacity: 0.7;
                        }

                        &:hover {
                            cursor: not-allowed;
                        }
                    }

                    .page-link {
                        color: color('text', 'darker') !important;
                    }
                }

                &.next {
                    &.disabled {
                        .page-link {
                            color: color('text', 'dark') !important;
                            opacity: 0.7;
                        }

                        &:hover {
                            cursor: not-allowed;
                        }
                    }

                    .page-link {
                        color: color('text', 'darker') !important;

                    }
                }

                .page-link {
                    background: transparent;
                    @include transition(all .3s linear);

                    &:hover {
                        color: color('white');
                        background: color('primary');
                    }
                }
            }
        }
    }

    .blog-item {
        text-align: left;
        padding-bottom: 1.5rem;

        .blog-link {
            &:hover {
                text-decoration: none;
            }
        }


        .blog-img-wrapper {
            img {
                vertical-align: middle;
                @include transition(all .2s linear);

                &:hover {
                    transform: scale(1.03);
                }
            }
        }

        .blog-date {
            font-family: font(sub);
            font-weight: 600;
            font-size: 0.9rem;
            text-align: left;
            display: block;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }

        .blog-title {
            font-family: font(main);
            font-weight: 500;
            font-size: 21px;
            color: color('text', 'dark');
            margin-top: 25px;
            height: auto !important;

            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .blog-desc {
            margin-top: 25px;
            margin-bottom: 25px;
            max-height: 125px !important;
            overflow: hidden !important;

            a {
                &:hover {
                    text-decoration: underline;
                }
            }

            p {
                font-size: 14px;
                font-family: font(sub);
                color: color('text', 'light');
                font-weight: 300;
                line-height: 24px;
            }
        }

        .more {
            color: color('primary');
            font-size: 14px;
            font-weight: 700;
            font-family: font(sub);

            i {
                font-size: 16px;
                color: color('primary');
                margin-right: 5px;
            }

            &:hover {
                text-decoration: none;

                i {
                    -webkit-animation: leftToRight 1s ease-out infinite;
                    animation: leftToRight 1s ease-out infinite;
                }
            }
        }
    }

}