.main-footer {
    color: color('footer', 'text', 'main');
    background: color('footer', 'background', 'main');

    .footer-section {
        padding-top: 25px;
        padding-bottom: 25px;

        &:last-of-type {
            // padding-bottom: 0;
        }

        &.footer-main {
            padding-top: 0;
            padding-bottom: 40px;

            .footer-contact {
                margin-top: 30px;

                @media(max-width: $media_lg) {
                    margin-bottom: 30px;
                }

                li {
                    font-family: font(sub);
                    font-weight: 300;
                    font-size: 14px;
                    @include transition(all .3s linear);
                    margin-bottom: 15px;
                    // padding-left: 30px;

                    span {
                        font-weight: 400;

                        &.company-name {
                            font-weight: 700;
                        }
                    }

                    a {
                        color: color('footer', 'text', 'main');

                        &:hover {
                            color: color('primary');
                            text-decoration: none;
                        }
                    }

                }
            }

            .footer-nav {
                &.column-2 {
                    -webkit-columns: 2;
                    -moz-columns: 2;
                    columns: 2;

                    @media (max-width: $media_md) {
                        -webkit-columns: 1;
                        -moz-columns: 1;
                        columns: 1;
                        margin: 0 auto;
                    }
                }

                ul {
                    font-size: 14px;
                    column-gap: 2em;
                    column-width: auto;

                    @media(max-width: $media_lg) {
                        text-align: center;
                    }

                    li {
                        font-family: font(main);
                        font-weight: 400;
                        text-transform: uppercase;
                        font-size: 14px;
                        margin-bottom: 20px;

                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        @media(max-width: $media_md) {
                            display: block;
                        }

                        a {
                            display: block;
                            padding: 1px;
                            @include transition(all .3s linear);

                            &:hover {
                                text-decoration: none;
                                color: color('primary');
                            }
                        }
                    }
                }
            }

            .footer-share {
                margin-top: 30px;
            }

            .footer-social {
                .social-title {
                    font-family: font(main);
                    font-weight: 500;
                    font-size: 14px;
                    display: block;
                    max-width: 70%;

                    @media (max-width: $media_lg) {
                        text-align: center;
                        max-width: 100%;
                    }
                }

                .social-icons {
                    padding-top: 2vh;
                    padding-bottom: 2vh;

                    @media (max-width: $media_lg) {
                        text-align: center;
                    }

                    .social-icon {
                        background: color('primary');
                        color: color('white');
                        text-align: center;
                        border-radius: 0;
                        @include transition(all .3s linear);
                        width: 35px;
                        height: 35px;
                        display: inline-block;
                        margin: 0 2.5px;
                        border: 1px solid color('primary');

                        &:hover {
                            background: color('white');
                            text-decoration: none;

                            i {
                                color: color('primary');
                            }
                        }

                        i {
                            font-size: 18px;
                            line-height: 35px;
                            color: color('white');
                            transition: all .3s linear;
                        }
                    }
                }

                .social-media {
                    padding-top: 2vh;
                    padding-bottom: 3vh;
                    text-align: right;

                    @media(max-width: $media_lg) {
                        text-align: center;
                    }

                    .at-share-btn {
                        background-color: transparent !important;
                        border: 1px solid color('border', 'dark');
                        @include transition(all .3s linear);
                        border-radius: $border_radius;

                        .at-icon-wrapper {
                            line-height: 35px !important;
                            width: 35px !important;
                            height: 35px !important;
                            text-align: center;
                        }

                        .at-icon {
                            line-height: 35px !important;
                            width: 20px !important;
                            height: 35px !important;
                        }

                        &:hover {
                            color: color('primary');
                            border-color: color('primary');

                        }

                        .at-icon:hover {
                            fill: color('primary') !important;
                        }
                    }
                }
            }
        }

        &.footer-bottom {
            color: color('text', 'light');
            font-size: 13px;
            font-weight: 300;
            font-family: font(sub);
            // background: color('footer', 'background', 'accent');

            @media(max-width: $media_md) {
                .col-12 {
                    padding-bottom: 10px;
                }
            }

            .dimedia-logo {
                max-height: 21px;
                max-width: auto;
            }
        }
    }

    .footer-copyright {
        text-align: center;
        margin-top: 1rem;

        span {
            font-family: font(sub);
            font-size: 13px;
            font-weight: 300;
        }
    }

}


@media (max-width: $media_lg) {
    #main-footer {
        .footer-navigation {
            display: none;
        }
    }
}


// STARTUP CODE


/* FOOTER STYLES */
.accent {
    color: color('footer', 'text', 'accent') !important;
    font-weight: 700;
}


.footer {
    font-size: 1.4rem;
    color: color('footer', 'text', 'main');
    padding: 50px 0;


    i {
        color: color('footer', 'text', 'accent');
        margin-right: 10px;
    }

    address {
        margin-bottom: 0;

        i {
            font-size: 16px;

            &.fa-skype {
                font-size: 15px;
            }

            &.fa-envelope {
                font-size: 14px;
            }

            &.fa-mobile {
                font-size: 20px;
                margin-right: 5px;
                position: relative;
                right: 2px;
            }
        }
    }
}


/* NEWSLETTER */
.dmn-input-group {
    max-height: 42px;

    .dmn-form-control {
        padding: 0.95rem 1.5rem;
        background-color: rgba(255, 255, 255, 0.02);
        border-radius: 0;
        border-color: color('border', 'light');
        color: #d6d6d6;
        font-size: 1.4rem;
        max-width: 220px;
        overflow: hidden;

        &:focus {
            box-shadow: 0 0 0 2px fade(color('secondary'), 20%);
        }
    }

    button {
        width: 55px;
        border: 1px solid color('secondary');
        background-color: color('secondary');
        border-radius: 0;
        font-size: 1.8rem;
        padding: 0.65rem 0.75rem;
        justify-content: center;
        @include transition(background 0.15s ease-in-out);

        i {
            margin: 0;
        }

        &:hover {
            background-color: lighten(color('secondary'), 2%);
        }
    }
}

// /* SOCIAL SHARE POPUP/LINKS */


/* TRANSITION/DELAY LOOP */


/* MEDIA QUERIES */
@media (max-width: $media_xl) {
    .footer-partners {
        p {
            img {
                margin: 10px auto 30px;
            }
        }
    }
}

@media (max-width: $media_lg) {
    .footer-links {
        display: none;
    }

    .footer-partners {
        p {
            img {
                display: block;
            }
        }
    }

    .footer-bottom {
        ul {
            flex-direction: column;
            text-align: center;
        }
    }
}

@media (max-width: $media_md) {
    .dmn-input-group {
        max-height: 36px;

        .dmn-form-control {
            padding: 0.95rem 1rem;
            font-size: 1.3rem;
        }

        button {
            width: 40px;
            padding: 0.35rem 0.75rem;
        }
    }


}

@media (max-width: $media_md) {
    .footer-logos {
        height: auto !important;

        li {
            padding-top: 15px;
        }
    }

    .footer-copyright {
        .row {
            div {
                text-align: center !important;
                padding-top: 10px;
            }
        }
    }
}

// @media (max-width: $media_sm) {
//     #main-footer {
//         .footer-share {
//             .social-media {
//                 .at-share-btn {

//                     .at-icon-wrapper {
//                         line-height: 25px !important;
//                         height: 25px !important;
//                         width: 25px !important;

//                         svg {
//                             width: 25px !important;
//                             height: 25px !important;
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     .social-container {
//         position: relative;
//         margin-top: 45px;
//     }
// }