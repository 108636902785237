.btn-circular {
    @include border-radius(40px !important);
}

.btn-shadow {
    @include box-shadow(2px 2px 3px 0px rgba(0, 0, 0, 0.3))
}

.btn-gradient {
    @include gradient-two-clr-linear(color('primary'), color('primary'));
    color: #fff !important;
    font-size: 1.2rem;
    border: none;
    line-height: 1.3rem;
    padding: 0.25rem 1rem;
    margin-top: 0.7rem;
    @include border-radius(0.3rem);
    @include transition(all 0.3s ease-in);

    &:hover {
        @include gradient-two-clr-linear(color('primary'), color('primary'));
    }
}


.btn-notebook-card {
    @include gradient-two-clr-linear(color('primary'), color('primary'));
    padding: 1px;
    display: inline-block;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    @include border-radius(0.3rem);
    font-size: 1.1rem;
    font-family: font(sub);
    font-weight: 400;

    // .inner-btn-text {
    //     // @include inner-btn-value;
    // }
}

@media (max-width: $media_lg) {
    .btn-notebook-card {
        margin-left: 3.5rem;
        margin-right: 3.5rem;
    }
}

@media (max-width: $media_md) {
    .btn-notebook-card {
        margin-left: 5rem;
        margin-right: 5rem;
    }
}

@media (max-width: $media_sm) {
    .btn-notebook-card {
        display: block;
        margin-left: 0;
        margin-right: 0;
    }
}

.btn-detail-card {
    @extend .btn-notebook-card;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}

/* BUTTON CLOSE */
.btn-close {
    // padding: 17px 53px;
    padding-left: 10px;
    color: color('white');
    font-size: 2rem;
    @include opacity(0.7);
    text-transform: uppercase;

    .btn-close-icon {
        font-size: 2rem;
        @include border-radius(50%);
        display: inline-block;
        // padding-right: 16px;
        // padding-left: 16px;
    }

    .btn-close-text {
        position: relative;
        top: -25%;
        left: 5%;
        line-height: 38px;
        display: inline-block;

    }

    &:hover {
        @include opacity(1);
    }

}

/* Button with primary background color */
.dm-button {
    font-family: font(main);
    font-size: 14px;
    font-weight: 600;
    padding: 0 12px;
    line-height: 34px;
    display: inline-block;
    background-color: color('primary');
    color: color('white');
    @include border-radius(0);
    @include transition(all 0.15s ease-in-out);

    &:link {
        color: color('primary');
    }

    &:hover {
        text-decoration: none;
        background-color: darken(color('primary'), 5%);
        border-color: darken(color('primary'), 5%);
        color: color('white');
    }

    /* BACKGROUND FILL ANIMATION --> remove if not using */
    &.fill {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: color('primary');
            z-index: -1;
            @include transition(top 0.1s ease-in);
        }

        &:hover {
            &:before {
                top: 100%;
            }
        }
    }

    /* ARROW ANIMATION */
    &.arrow {
        overflow: hidden;
        position: relative;

        &:after {
            content: "\f178";
            font-family: 'FontAwesome';
            opacity: 0;
            font-weight: 100;
            width: 0;
            display: inline-block;
            text-indent: 0;
            @include transition(all 250ms cubic-bezier(0.680, -0.550, 0.265, 1));
        }

        &:hover {
            &:after {
                width: 20px;
                text-indent: 8px;
                opacity: 1;
            }
        }
    }

    /* Button link */
    &.link {
        color: color('primary');
        font-weight: 400;
        padding: 0;
        border: 0;
        background-color: transparent !important;

        i {
            margin-left: 10px;
        }

        &:hover {
            i {
                -webkit-animation: leftToRight 1s ease-out infinite;
                animation: leftToRight 1s ease-out infinite;
            }
        }
    }

    /* Button with light border, text color and transparent background */
    &.outline {
        background-color: transparent !important;
        border-color: color('border', 'light');
        color: #ffffff;
        font-weight: 400;
    }

    /* Button with secondary background color */
    &.secondary {
        background-color: color('secondary');
        border-color: color('secondary');

        &:hover {
            background-color: darken(color('secondary'), 5%);
            border-color: darken(color('secondary'), 5%);
        }
    }

    /* Button with dark background color */
    &.dark {
        background-color: color(dark);
        border-color: color(dark);
        color: #ffffff;

        &:hover {
            background-color: darken(color(dark), 5%);
            border-color: darken(color(dark), 5%);
        }
    }

    &[type="submit"] {
        cursor: pointer;
    }
}


/* BACK TO TOP */
.back-to-top {
    width: 40px;
    height: 40px;
    @include border-radius($border_radius);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    line-height: 40px;
    text-align: center;
    display: inline-block;
    // background-color: color('secondary');
    background: color('text', 'dark');
    position: fixed;
    right: 5px;
    bottom: 10px; // 20px
    transform: translate(200px, 0);
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    z-index: 30 !important;
  
    i {
      font-size: 18px;
      color: #ffffff;
    }
  
    &.animate-in {
      transform: translate(0, 0);
    }
  
    &.animate-out {
      transform: transalte(200px, 0);
    }
  }