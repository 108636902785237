.section {
    padding-top: 40px;
    padding-bottom: 40px;

    hr.section-separator {
        border: none;
        height: 1px;
        background: #fae06e;
        width: 100%;
        position: relative;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .section-title {
        font-family: font(custom);
        font-size: 40px;
        font-weight: 300;
        color: color('primary');
        text-align: left;
        text-transform: uppercase;

        &h1 {
            font-size: 40px;
        }
    }

    .section-subtitle {
        font-family: font(main);
        font-weight: 300;
        font-size: 28px;
        color: color('text', 'dark');
        text-align: center;
        font-style: italic;
    }

    .section-text {
        font-family: font(sub);
        font-weight: 300;
        font-size: 14px;
        color: color('text', 'light');
        margin-top: 20px;
        margin-bottom: 20px;
        @include page-text();
    }

    .section-btn {
        margin: 0 auto;
        color: color('white') !important;
    }
}