/* FONT IMPORTS */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');

//@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700&display=swap');
//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,800');
//@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700,800');

@font-face {
    font-family: 'London One';
    src: url('../../../fonts/LondonOne.woff2') format('woff2'),
    url('../../../fonts/LondonOne.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* FONTS */
$fonts: (
    OpenSans: ('Open Sans', sans-serif),
    custom: ('London One', sans-serif),
    main: ('Lora', serif),
    sub: ('Open Sans', sans-serif),
);

@function font($key) {
    @if map-has-key($fonts, $key) {
        @return map-get($fonts, $key);
    }

    @warn "Unknown `#{$key}` in $fonts.";
    @return null;
}